import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/layout-mdx.jsx";
import { graphql } from "gatsby";
import { getColorByID } from "../../components/utils";
export const commissie = [{
  function: "Voorzitter & band",
  name: "Silke van der Burg"
}, {
  function: "Secretaris & Kostuum",
  name: "Lieke Schneidenberg"
}, {
  function: "Penningsmeester & Zang",
  name: "Lot van Leeuwen"
}, {
  function: "Theater, Promo & Spel",
  name: "Maria Geuze"
}, {
  function: "Vice & Dans",
  name: "Sophia Brans"
}, {
  function: "Extern & Decor",
  name: "Thijs van Veldhoven"
}, {
  function: "Theater & Techniek",
  name: "Martin Geertjes"
}];
export const query = graphql`
  {
    mdx(frontmatter: { slug: { eq: "/memories/lustrum-productie" } }) {
      fileAbsolutePath
      frontmatter {
        commiteePhoto {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Introduction = makeShortcode("Introduction");
const Committee = makeShortcode("Committee");
const MediaHighlighter = makeShortcode("MediaHighlighter");
const Blockquote = makeShortcode("Blockquote");
const Feedback = makeShortcode("Feedback");
const layoutProps = {
  commissie,
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Introduction color={getColorByID(props.pageContext.frontmatter.eventID)} title={props.pageContext.frontmatter.title} mdxType="Introduction">
  Ieder jaar wordt er voor en door Virgilianen een fantastische productie
  neergezet op de planken van Theater de Veste. Dit jaar vond het echter op een
  andere locatie plaats in verband met het lustrum. Na ruim een jaar lang
  zwoegen en zweten is op 11 juni 2018 Shrek de Musical opgevoerd in het
  grootste theater van Nederland, namelijk het AFAS Circustheater in
  Scheveningen.
    </Introduction>
    <Committee commiteeName="Lustrum Productie" members={commissie} image={props.data.mdx.frontmatter.commiteePhoto} mdxType="Committee" />
    <MediaHighlighter event={props.pageContext.frontmatter.eventID} mdxType="MediaHighlighter" />
    <h2>{`Vanuit de Lustrum Productie`}</h2>
    <p>{`Het lustrumjaar kwam er aan en dat betekende dat er een lustrum productie aan
zat te komen. Om deze reden zijn we een stuk eerder gevraagd als commissie, en
met een persoon extra ten opzicht van normaal. Al vroeg konden we ons gaan
focussen op het vinden van een geschikte locatie waarmee onderscheid kon
worden gemaakt met andere jaren. Het AFAS Circustheater zou voor ons de ideale
locatie worden en voor het stuk werd al snel duidelijk dat het Shrek de
Musical moest worden. Voor alle deelnemers was afgelopen jaar een unieke kans
om in een theater te spelen en een stuk op te voeren die anders niet mogelijk
is. Er is een show neergezet waarvan niemand had gedacht dat het zou lukken.
Iedereen heeft hard naar het einde toegewerkt en het resultaat mocht er daarom
zeker weten zijn!`}</p>
    <Blockquote align="right" mdxType="Blockquote">
  "Het AFAS Circustheater zou voor ons de ideale locatie worden en voor het stuk
  werd al snel duidelijk dat het Shrek de Musical moest worden."
    </Blockquote>
    <figure {...{
      "className": "gatsby-resp-image-figure",
      "style": {}
    }}>{`
    `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b9bc476531f2e4a13132e94eae326a17/00e0f/Virgiel%20-%20Theater-252.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.79536679536679%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGW/Q2I4B//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIDEQT/2gAIAQEAAQUCjFssxo546rdrR//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/Aaf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGH/8QAGRAAAgMBAAAAAAAAAAAAAAAAAREAAhAD/9oACAEBAAY/AmnAuaOKEC2f/8QAHBAAAgICAwAAAAAAAAAAAAAAAAERITFRcaGx/9oACAEBAAE/IY9IEQ5nsVrsRfanQjgPkeT/2gAMAwEAAgADAAAAEPc//8QAFhEBAQEAAAAAAAAAAAAAAAAAARAh/9oACAEDAQE/EAmM/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAh/9oACAECAQE/EFuk/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARIUExgf/aAAgBAQABPxACyGhGEn6gxfbWEFS7VUwwQGI0+w6t7P/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "AFAS Circustheater",
            "title": "AFAS Circustheater",
            "src": "/static/b9bc476531f2e4a13132e94eae326a17/af659/Virgiel%20-%20Theater-252.jpg",
            "srcSet": ["/static/b9bc476531f2e4a13132e94eae326a17/8356d/Virgiel%20-%20Theater-252.jpg 259w", "/static/b9bc476531f2e4a13132e94eae326a17/bc760/Virgiel%20-%20Theater-252.jpg 518w", "/static/b9bc476531f2e4a13132e94eae326a17/af659/Virgiel%20-%20Theater-252.jpg 1035w", "/static/b9bc476531f2e4a13132e94eae326a17/51eb8/Virgiel%20-%20Theater-252.jpg 1553w", "/static/b9bc476531f2e4a13132e94eae326a17/33042/Virgiel%20-%20Theater-252.jpg 2070w", "/static/b9bc476531f2e4a13132e94eae326a17/00e0f/Virgiel%20-%20Theater-252.jpg 5227w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
        "className": "gatsby-resp-image-figcaption"
      }}>{`AFAS Circustheater`}</figcaption>{`
  `}</figure>
    <Feedback filePath={props.data.mdx.fileAbsolutePath} mdxType="Feedback" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      